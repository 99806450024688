import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import Hero from '../components/shared/Hero';
import SliceBody from '../components/SliceBody';
import PrivateContent from '../components/shared/PrivateContent';


class KorusLounge extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page("Korus Lounge", {
      title: `${this.props.data.prismicKorusLoungePage.data.title} | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const {data} = this.props;
    const rawPageData = JSON.parse(data.prismicKorusLoungePage.dataString);
    const pageData = data.prismicKorusLoungePage.data;

    return (
      <Layout reverseLogo>
        <Helmet>
          {titleTags(`${pageData.title} | ${data.prismicSite.data.name}`)}
          {descriptionTags(pageData.hero_brief.text)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          title={pageData.hero_message}
          message={pageData.title}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
        />
        <PrivateContent
          password={pageData.password}
        >
          <SliceBody
            postData={rawPageData}
          />
        </PrivateContent>
      </Layout>
    );
  }
}


export default KorusLounge;

export const query = graphql`
  query KorusLoungeQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicKorusLoungePage {
      dataString
      data {
        title
        password
        hero_message
        hero_brief {
          html
          text
        }
        hero_image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              
            }
          }
        }
      }
    }
  }
`;
